import styles from "./Seo.module.scss";
import React from "react";
import {
  Box,
  Divider,
  TextField,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import WithLabel from "components/atoms/WithLabel/WithLabel";
import SelectX from "components/atoms/SelectX/SelectX";
import InputX from "components/atoms/InputX/InputX";
import { useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import { updateHandle } from "slices/collectionFormSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { title } from "process";

const Seo = () => {

  const collectionFormData = useAppSelector((state: RootState) => state.collectionForm.collectionForm);

  const dispatch = useDispatch();

  const handleChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateHandle(event.target.value));
  };

  const { id } = useParams<{ id?: string }>();
  const action = (id == null ? 'create' : 'edit');

  return (
    <Box className={styles.Seo}>
      <Box className={styles.header}> For SEO </Box>
      <Divider className={styles.divider} />

      <Box className={styles.gridContainer}>
        <Box className={styles.gridItem}>
          <WithLabel
            label="Handle"
            labelPosition="left"
            className={styles.fieldClass}
          >
            <InputX
              placeholder="Handle"
              required={false}
              error={""}
              value={collectionFormData?.handle}
              onChange={handleChangeHandle}
              disabled
            />
          </WithLabel>
        </Box>
      </Box>

    </Box>
  );
};

export default Seo;
