// filtersSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProductCatalogueStatusType = 'all' | 'active' | 'draft' | 'archived';

interface FiltersState {
  productCatalogueStatusFilter: ProductCatalogueStatusType;
  productCatalogueCategoryFilter: string;
  productCatalogueSubCategoryFilter: string;
  productCatalogueVendorFilter: string;
  productCatalogueTitleFilter: string;
  productCatalogueSkuFilter: string;
  productCatalogueVinFilter: string;
  productCatalogueHsnCodeFilter: string;
  productCatalogueFbvEnabledFilter: boolean;
  productCatalogueCreatedAtStartDateFilter: Date | undefined;
  productCatalogueCreatedAtEndDateFilter: Date | undefined;
  productCatalogueInventoryFilter: string;
  collectionCatalogueSkuFilter: string;
  collectionCatalogueCollectionNameFilter: string;
  collectionCatalogueCollectionTypeFilter: string;
  collectionCatalogueCreatedAtStartDateFilter: Date | undefined;
  collectionCatalogueCreatedAtEndDateFilter: Date | undefined;
  categoryMasterCatalogueNameFilter: string;
  categoryMasterCatalogueCreatedAtStartDateFilter: Date | undefined;
  categoryMasterCatalogueCreatedAtEndDateFilter: Date | undefined;
  subCategoryMasterCatalogueNameFilter: string;
  subCategoryMasterCatalogueCategoryFilter: string;
  subCategoryMasterCatalogueCreatedAtStartDateFilter: Date | undefined;
  subCategoryMasterCatalogueCreatedAtEndDateFilter: Date | undefined;
  productTypeMasterCatalogueNameFilter: string;
  productTypeMasterCatalogueCategoryFilter: string;
  productTypeMasterCatalogueSubCategoryFilter: string;
  productTypeMasterCatalogueCreatedAtStartDateFilter: Date | undefined;
  productTypeMasterCatalogueCreatedAtEndDateFilter: Date | undefined;
  setPiecesMasterCatalogueNameFilter: string;
  setPiecesMasterCatalogueCreatedAtStartDateFilter: Date | undefined;
  setPiecesMasterCatalogueCreatedAtEndDateFilter: Date | undefined;
  allMasterCatalogueNameFilter: string;
  allMasterCatalogueValueFilter: string;
  allMasterCatalogueCreatedAtStartDateFilter: Date | undefined;
  allMasterCatalogueCreatedAtEndDateFilter: Date | undefined;
  imageUploadRequestReportFileNameFilter: string;
  imageUploadRequestReportStatusFilter: string;
  collectionBannerNameFilter: string;
  requestTrackingFileNameFilter: string;
  vendorDataValidationRequestReportFileNameFilter: string;
  vendorDataValidationRequestReportStatusFilter: string;
}

export const initialState: FiltersState = {
  productCatalogueStatusFilter: 'all',
  productCatalogueCategoryFilter: '',
  productCatalogueSubCategoryFilter: '',
  productCatalogueVendorFilter: '',
  productCatalogueTitleFilter: '',
  productCatalogueSkuFilter: '',
  productCatalogueVinFilter: '',
  productCatalogueHsnCodeFilter: '',
  productCatalogueFbvEnabledFilter: false,
  productCatalogueCreatedAtStartDateFilter: undefined,
  productCatalogueCreatedAtEndDateFilter: undefined,
  productCatalogueInventoryFilter: '',
  collectionCatalogueSkuFilter: '',
  collectionCatalogueCollectionNameFilter: '',
  collectionCatalogueCollectionTypeFilter: '',
  collectionCatalogueCreatedAtStartDateFilter: undefined,
  collectionCatalogueCreatedAtEndDateFilter: undefined,
  categoryMasterCatalogueNameFilter: '',
  categoryMasterCatalogueCreatedAtStartDateFilter: undefined,
  categoryMasterCatalogueCreatedAtEndDateFilter: undefined,
  subCategoryMasterCatalogueNameFilter: '',
  subCategoryMasterCatalogueCategoryFilter: '',
  subCategoryMasterCatalogueCreatedAtStartDateFilter: undefined,
  subCategoryMasterCatalogueCreatedAtEndDateFilter: undefined,
  productTypeMasterCatalogueNameFilter: '',
  productTypeMasterCatalogueCategoryFilter: '',
  productTypeMasterCatalogueSubCategoryFilter: '',
  productTypeMasterCatalogueCreatedAtStartDateFilter: undefined,
  productTypeMasterCatalogueCreatedAtEndDateFilter: undefined,
  setPiecesMasterCatalogueNameFilter: '',
  setPiecesMasterCatalogueCreatedAtStartDateFilter: undefined,
  setPiecesMasterCatalogueCreatedAtEndDateFilter: undefined,
  allMasterCatalogueNameFilter: '',
  allMasterCatalogueValueFilter: '',
  allMasterCatalogueCreatedAtStartDateFilter: undefined,
  allMasterCatalogueCreatedAtEndDateFilter: undefined,
  imageUploadRequestReportFileNameFilter: '',
  imageUploadRequestReportStatusFilter: '',
  collectionBannerNameFilter: '',
  requestTrackingFileNameFilter: '',
  vendorDataValidationRequestReportFileNameFilter: '',
  vendorDataValidationRequestReportStatusFilter: ''
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setProductCatalogueStatusFilter: (state, action: PayloadAction<ProductCatalogueStatusType>) => {
      state.productCatalogueStatusFilter = action.payload;
    },
    setProductCatalogueCategoryFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueCategoryFilter = action.payload;
    },
    setProductCatalogueSubCategoryFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueSubCategoryFilter = action.payload;
    },
    setProductCatalogueVendorFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueVendorFilter = action.payload;
    },
    setProductCatalogueTitleFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueTitleFilter = action.payload;
    },
    setProductCatalogueSkuFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueSkuFilter = action.payload;
    },
    setProductCatalogueVinFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueVinFilter = action.payload;
    },
    setProductCatalogueHsnCodeFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueHsnCodeFilter = action.payload;
    },
    setProductCatalogueFbvEnabledFilter: (state, action: PayloadAction<boolean>) => {
      state.productCatalogueFbvEnabledFilter = action.payload;
    },
    setproductCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.productCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setproductCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.productCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setProductCatalogueInventoryFilter: (state, action: PayloadAction<string>) => {
      state.productCatalogueInventoryFilter = action.payload;
    },
    setCollectionCatalogueSkuFilter: (state, action: PayloadAction<string>) => {
      state.collectionCatalogueSkuFilter = action.payload;
    },
    setCollectionCatalogueCollectionNameFilter: (state, action: PayloadAction<string>) => {
      state.collectionCatalogueCollectionNameFilter = action.payload;
    },
    setCollectionCatalogueCollectionTypeFilter: (state, action: PayloadAction<string>) => {
      state.collectionCatalogueCollectionTypeFilter = action.payload;
    },
    setcollectionCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.collectionCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setcollectionCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.collectionCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setCategoryMasterCatalogueNameFilter: (state, action: PayloadAction<string>) => {
      state.categoryMasterCatalogueNameFilter = action.payload;
    },
    setCategoryMasterCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.categoryMasterCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setCategoryMasterCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.categoryMasterCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setSubCategoryMasterCatalogueNameFilter: (state, action: PayloadAction<string>) => {
      state.subCategoryMasterCatalogueNameFilter = action.payload;
    },
    setSubCategoryMasterCatalogueCategoryFilter: (state, action: PayloadAction<string>) => {
      state.subCategoryMasterCatalogueCategoryFilter = action.payload;
    },
    setSubCategoryMasterCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.subCategoryMasterCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setSubCategoryMasterCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.subCategoryMasterCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setProductTypeMasterCatalogueNameFilter: (state, action: PayloadAction<string>) => {
      state.productTypeMasterCatalogueNameFilter = action.payload;
    },
    setProductTypeMasterCatalogueCategoryFilter: (state, action: PayloadAction<string>) => {
      state.productTypeMasterCatalogueCategoryFilter = action.payload;
    },
    setProductTypeMasterCatalogueSubCategoryFilter: (state, action: PayloadAction<string>) => {
      state.productTypeMasterCatalogueSubCategoryFilter = action.payload;
    },
    setProductTypeMasterCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.productTypeMasterCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setProductTypeMasterCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.productTypeMasterCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setSetPiecesMasterCatalogueNameFilter: (state, action: PayloadAction<string>) => {
      state.setPiecesMasterCatalogueNameFilter = action.payload;
    },
    setSetPiecesMasterCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.setPiecesMasterCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setSetPiecesMasterCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.setPiecesMasterCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setAllMasterCatalogueNameFilter: (state, action: PayloadAction<string>) => {
      state.allMasterCatalogueNameFilter = action.payload;
    },
    setAllMasterCatalogueValueFilter: (state, action: PayloadAction<string>) => {
      state.allMasterCatalogueValueFilter = action.payload;
    },
    setAllMasterCatalogueCreatedAtStartDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.allMasterCatalogueCreatedAtStartDateFilter = action.payload;
    },
    setAllMasterCatalogueCreatedAtEndDateFilter: (state, action: PayloadAction<Date|undefined>) => {
      state.allMasterCatalogueCreatedAtEndDateFilter = action.payload;
    },
    setImageUploadRequestReportFileNameFilter: (state, action: PayloadAction<string>) => {
      state.imageUploadRequestReportFileNameFilter = action.payload;
    },
    setImageUploadRequestReportStatusFilter: (state, action: PayloadAction<string>) => {
      state.imageUploadRequestReportStatusFilter = action.payload;
    },
    setCollectionBannerNameFilter: (state, action: PayloadAction<string>) => {
      state.collectionBannerNameFilter = action.payload;
    },
    setRequestTrackingFileNameFilter: (state, action: PayloadAction<string>) => {
      state.requestTrackingFileNameFilter = action.payload;
    },
    setVendorDataValidationRequestReportFileNameFilter: (state, action: PayloadAction<string>) => {
      state.vendorDataValidationRequestReportFileNameFilter = action.payload;
    },
    setVendorDataValidationRequestReportStatusFilter: (state, action: PayloadAction<string>) => {
      state.vendorDataValidationRequestReportStatusFilter = action.payload;
    },
    resetFilter: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setProductCatalogueStatusFilter,
  setProductCatalogueCategoryFilter,
  setProductCatalogueSubCategoryFilter,
  setProductCatalogueVendorFilter,
  setProductCatalogueTitleFilter,
  setProductCatalogueSkuFilter,
  setProductCatalogueVinFilter,
  setProductCatalogueHsnCodeFilter,
  setProductCatalogueFbvEnabledFilter,
  setproductCatalogueCreatedAtStartDateFilter,
  setproductCatalogueCreatedAtEndDateFilter,
  setProductCatalogueInventoryFilter,
  setCollectionCatalogueSkuFilter,
  setCollectionCatalogueCollectionNameFilter,
  setCollectionCatalogueCollectionTypeFilter,
  setcollectionCatalogueCreatedAtStartDateFilter,
  setcollectionCatalogueCreatedAtEndDateFilter,
  setCategoryMasterCatalogueNameFilter,
  setCategoryMasterCatalogueCreatedAtStartDateFilter,
  setCategoryMasterCatalogueCreatedAtEndDateFilter,
  setSubCategoryMasterCatalogueNameFilter,
  setSubCategoryMasterCatalogueCategoryFilter,
  setSubCategoryMasterCatalogueCreatedAtStartDateFilter,
  setSubCategoryMasterCatalogueCreatedAtEndDateFilter,
  setProductTypeMasterCatalogueNameFilter,
  setProductTypeMasterCatalogueCategoryFilter,
  setProductTypeMasterCatalogueSubCategoryFilter,
  setProductTypeMasterCatalogueCreatedAtStartDateFilter,
  setProductTypeMasterCatalogueCreatedAtEndDateFilter,
  setSetPiecesMasterCatalogueNameFilter,
  setSetPiecesMasterCatalogueCreatedAtStartDateFilter,
  setSetPiecesMasterCatalogueCreatedAtEndDateFilter,
  setAllMasterCatalogueNameFilter,
  setAllMasterCatalogueValueFilter,
  setAllMasterCatalogueCreatedAtStartDateFilter,
  setAllMasterCatalogueCreatedAtEndDateFilter,
  setImageUploadRequestReportFileNameFilter,
  setImageUploadRequestReportStatusFilter,
  setCollectionBannerNameFilter,
  setRequestTrackingFileNameFilter,
  setVendorDataValidationRequestReportFileNameFilter,
  setVendorDataValidationRequestReportStatusFilter,
  resetFilter
} = filtersSlice.actions;
export default filtersSlice.reducer;
