import { Box, ClickAwayListener, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import styles from "./MultiSelectX.module.scss";
import { csx } from "helpers/utils";
import CheckboxFilter from "../CheckboxFilter/CheckboxFilter";
import { useMemo, useState } from "react";

type MultiSelectXPropType = {
  label?: JSX.Element | React.ReactNode;
  options: { label: string, value: string, renderedLabel?: JSX.Element | React.ReactNode }[];
  values: string[] | undefined;
  onChange: (value: string[]) => void;
  className?: string;
  wrapperClass?: string;
  labelClass?: string;
  inputWrapperClass?: string;
  required?: boolean;
  error?: string;
  allowSearch?: boolean;
  placeholder?:string;
  disabled?: boolean;
  allowAllSelect?: boolean;
}

const MultiSelectX: React.FC<MultiSelectXPropType> = (props) => {
  const { required, label, options, values, onChange, className, wrapperClass, error, labelClass, inputWrapperClass, allowSearch, placeholder = 'Select', disabled, allowAllSelect = false} = props;

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const renderLabel = useMemo(() => {
    return options.filter((op) => values?.includes(op.value)).map((op) => op.label).join(' • ');
  }, [options, values]);

  const handleFilterSelection = (toggleValue: string) => {
    if(values?.includes(toggleValue)){
      onChange(values.filter((val) => val !== toggleValue))
    }else{
      onChange([...(values || []),  toggleValue]);
    }
  }

  const selectAllOptions = () => {
    if(options.length === values?.length){
      onChange([]);
    }else{
      onChange(options.map((o) => o.value));
    }
  }

  return (
    <Box className={csx(wrapperClass, styles.selectXWrapper)}>
      {!!label && (
        <Box className={csx(labelClass, styles.textLabel)}>
          {label} {required ? <sup>*</sup> : null}
        </Box>
      )}
      <Box className={csx(inputWrapperClass, styles.inputWrapper)}>
        <FormControl fullWidth error={!!error}>
          <Select
            className={csx(className, styles.selectX)}
            fullWidth
            open={showOptions}
            onClick={() => !disabled && setShowOptions(true)}
            error={!!error}
            renderValue={() => !!values?.length ? renderLabel : <p className={styles.placeholder}>{placeholder}</p>}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            native={false}
            disabled={disabled}
          >
            <ClickAwayListener onClickAway={() => setShowOptions(false)}>
              <Box className={styles.optionsWrapper}>
                {allowSearch && (
                  <Box className={styles.searchBar}>
                    <input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                  </Box>
                )}
                <Box className={styles.options}>
                  {!!options.length && allowAllSelect && (
                      <MenuItem
                      key={-1} 
                      value={-1} 
                      dense
                      className={csx(styles.selectOption, options.length === values?.length ? styles.selected : undefined)} 
                      onClick={(e) => {
                        e.stopPropagation();
                        selectAllOptions();
                      }}
                    >
                      <CheckboxFilter
                        label={'Select All'}
                        checked={options.length === values?.length}
                        onChange={() => {}}
                      />
                      </MenuItem>
                    )}
                  {options.map((option) => {
                    if(!option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())) return null;
                    return (
                      <MenuItem
                        key={option.value} 
                        value={option.value} 
                        dense
                        className={csx(styles.selectOption, values?.includes(option.value) ? styles.selected : undefined)} 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFilterSelection(option.value)
                        }}
                      >
                        <CheckboxFilter
                          label={option.renderedLabel || option.label}
                          checked={values?.includes(option.value) || false}
                          onChange={() => {}}
                        />
                      </MenuItem>
                  )})}
                </Box>
              </Box>
            </ClickAwayListener>
          </Select>
          {!!error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Box>
    </Box>
  )
}

export default MultiSelectX;