import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import styles from "./ModalX.module.scss";
import ButtonX from "../ButtonX/ButtonX";
import { DEFAULT_BLACK_COLOR } from "../../../constants";
import { csx } from "helpers/utils";

type ModalXProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  onSubmit?: () => void;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  isSubmitDisabled?: boolean;
  className?: string;
};

const ModalX = ({
  open,
  onClose,
  title,
  children,
  onSubmit,
  primaryBtnLabel = "Create",
  secondaryBtnLabel = "Discard",
  isSubmitDisabled = false,
  className
}: ModalXProps) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <Box className={csx(styles.modalWrapper, className)}>
        <Typography variant="h6" id="modal-title" className={styles.modalTitle}>
          {title}
        </Typography>
        <Box className={styles.modalContent}>{children}</Box>
        <Box className={styles.modalActions}>
          <ButtonX
            variant="outlined"
            color={DEFAULT_BLACK_COLOR}
            onClick={onClose}>
            {secondaryBtnLabel}
          </ButtonX>
          <ButtonX
            className={styles.primaryBtn}
            onClick={onSubmit}
            disabled={isSubmitDisabled}
          >
            {primaryBtnLabel}
          </ButtonX>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalX;
