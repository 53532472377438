import { ColumnPropType } from "../../components/organisms/TableManager/TableManager";
import styles from "./CollectionBanner.module.scss";
import { CollectionBannerDataHash } from "slices/collectionBannerSlice";
import { ROUTES } from "../../constants";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { Box } from "@mui/material";
import Img from "components/atoms/Img/Img";

interface CollectionBannerColumnsPropType {
  navigate: (path: string) => void;
  handleDelete: (bannerId: number) => void;
}

export const collectionBannerColumns = (
  props: CollectionBannerColumnsPropType
): ColumnPropType<CollectionBannerDataHash>[] => {
  const { navigate, handleDelete } = props;

  const handleView = (bannerId: number) => {
    navigate(ROUTES.COLLECTION_BANNER_EDIT.replace(":id", bannerId.toString()));
  };

  return [
    {
      label: "Image",
      accessor: "banner",
      minWidth: 150,
      cell: ({ row }) => {
        const { banner, banner_alt_text } = row;
        return (
          <Box className={styles.collectionBannerImage}>
            <Img src={banner || ""} alt={banner_alt_text || "Banner Image"} />
          </Box>
        );
      },
    },
    {
      label: "Banner Name",
      accessor: "display_name",
      minWidth: 200,
    },
    {
      label: "Created At",
      accessor: "created_at",
      maxWidth: 240,
    },
    {
      label: "Created By",
      accessor: "created_by",
      minWidth: 120,
    },
    {
      label: "No of Collections",
      accessor: "collections_count",
      minWidth: 150,
    },

    {
      label: "Size",
      accessor: "size",
      minWidth: 120,
      cell: ({ value }) => {
        return (
          `${Math.floor(value / 1000)} KB`
        );
      },
    },
    {
      label: "",
      accessor: "id",
      minWidth: 120,
      cell: ({ row }) => {
        return (
          <Box className={styles.actionBtns}>
            <ButtonX
              size="small"
              onClick={() => handleView(row.id)}
              className={styles.primaryBtn}
            >
              View
            </ButtonX>
            <ButtonX
              variant="text"
              size="small"
              className={styles.deleteBtn}
              onClick={() => handleDelete(row.id)}
            >
              Delete
            </ButtonX>
          </Box>
        );
      },
    },
  ];
};
