export const BACKEND_BASE_URL = "https://api.vaaree.com";

export const ROUTES = {
  PRODUCTS: "/",
  COLLECTIONS: "/all_collections",
  EDIT_COLLECTION: "/collections/:id/edit",
  NEW_PRODUCT: "/products/new",
  EDIT_PRODUCT: "/products/:id/edit",
  MASTERS: '/masters',
  CATEGORIES: '/masters/categories',
  SUB_CATEGORIES: '/masters/sub_categories',
  PRODUCT_TYPES: '/masters/product_types',
  SET_PIECES: '/masters/set_pieces',
  REQUESTS: '/request-tracking',
  BULK_IMAGE_UPLOAD: '/content/bulk-image-upload',
  COLLECTION_BANNER: '/content/collection-banner',
  COLLECTION_BANNER_EDIT: '/content/collection-banner/:id/edit',
  VENDOR_DATA_VALIDATIONS: '/content/vendor-data-validations',
};

export type DateRangeType = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export type MonthYearType = {
  month: number | undefined;
  year: number | undefined;
};


export type AttachmentFile = {
  filename: string;
}

export const DEFAULT_YELLOW_COLOR = '#FFC845';
export const DEFAULT_BLACK_COLOR = '#211913';
