import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom"; // Updated import
import { Box } from "@mui/material";
import MainHeader from "components/atoms/MainHeader/MainHeader";
import EditCollectionBanner from "./Components/EditCollectionBanner";
import styles from "./CollectionBannerForm.module.scss";
import { CollectionBannerFormData, fetchCollectionBannerDetails, resetForm, updateCollectionBanner, updateForm } from "slices/collectionBannerFormSlice";
import Loader from "components/atoms/Loader/Loader";
import { RootState, AppDispatch } from "../../store";
import { resetAuth } from "slices/authSlice";
import { resetFilter } from "slices/filtersSlice";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import ModalX from "components/atoms/ModalX/ModalX";

const CollectionBannerForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate(); // Updated to useNavigate

  const [validationErrors, setValidationErrors] = useState<Partial<Record<keyof CollectionBannerFormData, string>>>({});
  const [loaderActive, setLoaderActive] = useState<boolean>(true);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [deletedCollectionIds, setDeletedCollectionIds] = useState<number[]>([]);


  const isEditMode = !!id;
  const { formData } = useSelector((state: RootState) => state.collectionBannerForm);
  const collectionBannerDetails = useSelector((state: RootState) => state.collectionBannerForm.formData);

  const fetchData = async () => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      try {
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

        if (isEditMode && id) {
          await dispatch(fetchCollectionBannerDetails({ id, headers })).unwrap();
        } else {
          dispatch(resetForm());
        }

      } catch (error) {
        console.log(error);
      } finally {
        setLoaderActive(false);
      }
    } else {
      dispatch(resetAuth());
      dispatch(resetFilter());
      setLoaderActive(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, id]);

  const handleDiscard = async () => {
    if (deletedCollectionIds.length > 0) {
      setShowDiscardModal(true);
    } else {
      window.location.href = "/content/collection-banner"
    }
  };

  const handleChange = (field: keyof typeof formData, value: any) => {
    dispatch(
      updateForm({
        ...formData,
        [field]: value,
      })
    );
  };

  const cancelDiscard = () => {
    setShowDiscardModal(false); // Close the modal
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoaderActive(true);
    try {
      const updatedCollections = formData.collections.filter(
        (collection) => !deletedCollectionIds.includes(Number(collection.id))
      );

      const submissionData = {
        ...formData,
        collections: updatedCollections.map((collection) => collection.id),
      };

      const headers = { "content-type": 'multipart/form-data' }
      await dispatch(updateCollectionBanner({ id, formData: submissionData, headers })).unwrap();
      navigate("/content/collection-banner");
      dispatch(resetForm())
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderActive(false);
    }
  };

  const handleModalDiscard = async () => {
    setShowDiscardModal(false);
    window.location.href = "/content/collection-banner"
  }

  const onRemoveCollections = (ids: number[]) => {
    setDeletedCollectionIds((prev) => [...new Set([...prev, ...ids])]); // Track deleted IDs
  };

  return (
    <Box className={styles.reportWrapper}>
      <Box >
        <Loader show={loaderActive} />
        {!loaderActive && (
          <>
            <MainHeader className={styles.header} label={collectionBannerDetails.banner_name || "Edit Banner"} allowBack={true}>
              <ButtonX color='#000' variant='outlined' onClick={handleDiscard}>Discard</ButtonX>
              <ButtonX onClick={handleSubmit}>{'Save'}</ButtonX>
            </MainHeader>
            <EditCollectionBanner
              editFormData={formData}
              onChange={handleChange}
              validationErrors={validationErrors}
              onRemoveCollections={onRemoveCollections}
            />
          </>
        )}
        <ModalX
          title="Want to go back without saving?"
          open={showDiscardModal}
          onClose={cancelDiscard}
          primaryBtnLabel="Discard"
          secondaryBtnLabel="Close"
          onSubmit={handleModalDiscard}
          className={styles.customModal}
        >
          <p>Your edits will not be saved and will revert to the previously saved version.</p>
        </ModalX>
      </Box>
    </Box>
  );
};

export default CollectionBannerForm;
