import React, { useEffect, useState } from "react";
import ModalX from "components/atoms/ModalX/ModalX";
import InputX from "components/atoms/InputX/InputX";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { Box, Typography, List, ListItem, ListItemText, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./AddCollectionModal.module.scss";
import { useAppDispatch } from "hooks/redux-hooks";
import { searchCollections } from "slices/collectionBannerFormSlice";
import Img from "components/atoms/Img/Img";

type Collection = {
  id: number;
  unique_key: number;
  name: string;
  marketplace_unique_id: number;
  image_url: string | null;
};

const allCollectionItem: Collection = {
  id: 0,
  unique_key: 0,
  name: 'All Collections',
  marketplace_unique_id: 0,
  image_url: "https://cdn.shopify.com/s/files/1/0632/2526/6422/collections/carpet-comfy-cove-carpet-1_8c8947aa-6e9a-4e35-9356-b330aacb8d53.jpg?v=1727788463"
}

type AddCollectionModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (selectedCollections: Collection[]) => void;
  alreadySelectedIds: number[];
};

const AddCollectionModal = ({ open, onClose, onSubmit, alreadySelectedIds }: AddCollectionModalProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCollections, setSelectedCollections] = useState<Collection[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [searchedCollections, setSearchedCollections] = useState<Collection[]>([]); // <-- State for search results
  const isAllCollectionItemPresent = !!selectedCollections.find((item) => item.id === allCollectionItem.id)
  const dispatch = useAppDispatch();

  // Filter collections based on the search term
  const filteredSearchedCollections = searchedCollections.filter(
    (collection) => !selectedCollections.some((c) => c.id === collection.id)
  );

  useEffect(() => {
    if (open) {
      setSearchedCollections([]);
      setSelectedCollections([]);
      setSearchTerm("");
      setSelectedIds(alreadySelectedIds);
    }
  }, [open, alreadySelectedIds]);

  // Add a single collection
  const handleAddCollection = (collection: Collection) => {
    setSelectedCollections((prev) => [...prev, collection]);
    setSelectedIds((prev) => [...prev, collection.id]);
  };

  // Add all filtered collections
  const handleAddAll = () => {
    setSelectedCollections((prev) => [...prev, ...filteredSearchedCollections]);
  };

  // Remove a single collection
  const handleRemoveCollection = (id: number) => {
    setSelectedCollections((prev) => prev.filter((collection) => collection.id !== id));
    setSelectedIds((prev) => prev.filter((selectedId) => id !== selectedId))
  };

  // Submit the selected collections
  const handleSubmit = () => {
    onSubmit(selectedCollections);
  };

  const onSearchTxt = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { nativeEvent: { key } } = e;
    if (key === 'Enter') {
      e.preventDefault();
      fetchCollections();
    }
  };

  const fetchCollections = async () => {
    try {
      const data = {
        searchTerm,
        selectedIds
      }
      const result = await dispatch(searchCollections(data)).unwrap();
      setSearchedCollections(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalX
      open={open}
      onClose={onClose}
      title="Add Collection"
      primaryBtnLabel="Add Collections"
      secondaryBtnLabel="Clear"
      onSubmit={handleSubmit}
      className={styles.customModal}
    >
      <Divider />
      <Box className={styles.modalWrapper}>
        {/* Search Input */}
        <Box display="flex" justifyContent="space-between">
          {/* Left Section: Search Result */}
          <Box flex={1} className={styles.listWrapper}>
            <Box mt={1} mb={2}>
              <InputX
                placeholder="Search Collections"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                labelClass={styles.searchInput}
                onKeyDown={onSearchTxt}
              />
            </Box>
            <List>
              {/* Show search results only when there are filtered results */}
              {filteredSearchedCollections.length > 0 && (
                <>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Search Result
                    </Typography>
                    <Typography
                      className={styles.addAll}
                      onClick={handleAddAll}
                      style={{ cursor: "pointer", fontWeight: 600 }}
                    >
                      Add All
                    </Typography>
                  </Box>
                  {filteredSearchedCollections.map((collection) => (
                    <ListItem key={collection.id} divider sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 1 }}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Img
                          src={collection.image_url || ""}
                          alt={collection.name}
                          className={styles.iconImage}
                        />
                        <ListItemText
                          primary={collection.name}
                          secondary={collection.marketplace_unique_id}
                        />
                      </Box>
                      <ButtonX
                        variant="outlined"
                        size="small"
                        onClick={() => handleAddCollection(collection)}
                      >
                        Add
                      </ButtonX>
                    </ListItem>
                  ))}
                </>
              )}

              {/* Show "No collections found" when search term exists but no results */}
              {searchTerm.trim() && searchedCollections.length === 0 && (
                <Typography color="textSecondary" textAlign="center">
                  No collections found
                </Typography>
              )}

              {/* Show a default item when no search term and no collections */}
              {!searchTerm.trim() && !isAllCollectionItemPresent && (
                <ListItem key={allCollectionItem.id} divider sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 1 }}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Img
                      src={allCollectionItem.image_url || ""}
                      alt={allCollectionItem.name}
                      className={styles.iconImage}
                    />
                    <ListItemText
                      primary={allCollectionItem.name}
                      secondary={allCollectionItem.marketplace_unique_id}
                    />
                  </Box>
                  <ButtonX
                    variant="outlined"
                    size="small"
                    onClick={() => handleAddCollection(allCollectionItem)}
                  >
                    Add
                  </ButtonX>
                </ListItem>
              )}
            </List>

          </Box>

          <Divider orientation="vertical" flexItem />

          {/* Right Section: Selected Collections */}
          <Box flex={1} className={styles.listWrapper}>
            <Typography variant="subtitle2" m={1}>
              Selected ({selectedCollections.length})
            </Typography>
            <List>
              {selectedCollections.map((collection) => (
                <ListItem key={collection.id} divider sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: 1 }}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Img
                      src={collection.image_url || ''}
                      alt={collection.name}
                      className={styles.iconImage}
                    />
                    <ListItemText
                      primary={collection.name}
                      secondary={collection.marketplace_unique_id}
                      primaryTypographyProps={{ fontSize: "0.875rem" }}
                      secondaryTypographyProps={{ fontSize: "0.75rem", color: "gray" }}
                    />

                  </Box>
                  <IconButton
                    onClick={() => handleRemoveCollection(collection.id)}
                    size="small"
                    color="error"
                  >
                    <CloseIcon fontSize="small" sx={{ color: "black" }} />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </ModalX >
  );
};

export default AddCollectionModal;
