import React, { useState, useEffect, useRef } from "react";
import ModalX from "../../components/atoms/ModalX/ModalX";
import styles from "./CollectionBanner.module.scss"; // Import the styles.
import InputX from "components/atoms/InputX/InputX";
import { Box } from "@mui/material";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type AddBannerModalProps = {
  open: boolean;
  onClose: () => void;
  onCreate: (bannerName: string, bannerLink: string, banner: File | null) => void;
};

const AddBannerModal = ({ open, onClose, onCreate }: AddBannerModalProps) => {
  const [bannerName, setBannerName] = useState("");
  const [bannerLink, setBannerLink] = useState("");
  const [banner, setBanner] = useState<File | null>(null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      if (previewURL) URL.revokeObjectURL(previewURL);
    };
  }, [previewURL]);

  useEffect(() => {
    if (open) {
      setBannerName("");
      setBannerLink("");
      setBanner(null);
      setPreviewURL(null);
      setError(null);
    }
  }, [open])

  // Handle file upload
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setBanner(file);

    if (file) {
      const objectURL = URL.createObjectURL(file);
      setPreviewURL(objectURL);
    }
  };

  // Remove the uploaded image
  const handleRemoveImage = () => {
    setBanner(null);
    setPreviewURL(null);
    const fileInput = document.getElementById("banner-file-input") as HTMLInputElement;
    if (fileInput) fileInput.value = "";
  };

  // Submit form data
  const handleSubmit = () => {
    if (banner && banner.size > 1000000) { // 1MB = 1000000 bytes
      setError("Maximum file size: 1 MB");
      return;
    }

    setError(null);
    onCreate(bannerName, bannerLink, banner);
  };

  return (
    <ModalX
      open={open}
      onClose={onClose}
      title="Add Collection Banner"
      onSubmit={handleSubmit}
      primaryBtnLabel="Create"
      secondaryBtnLabel="Discard"
      isSubmitDisabled={!bannerName || !banner}
    >
      <div>
        {/* Banner Name Input */}
        <Box className={styles.modalContent}>
          <InputX
            label="Banner Name"
            value={bannerName}
            wrapperClass={styles.tagInput}
            placeholder="Banner Name"
            autoComplete="off"
            onChange={(e) => setBannerName(e.target.value)}
            required
          />
          <div className={styles.supportedFormats}>
            Max 40 character
          </div>
        </Box>

        {/* Banner URL Input */}
        <Box className={styles.modalContent}>
          <InputX
            label="Banner URL"
            value={bannerLink}
            wrapperClass={styles.tagInput}
            placeholder="Banner URL"
            autoComplete="off"
            onChange={(e) => setBannerLink(e.target.value)}
          />
        </Box>

        {/* File Upload */}
        <Box className={styles.modalContent}>
          <label className={styles.label}>Add Banner *</label>
          <div
            className={`${styles.uploadBox} ${error ? styles.errorBorder : ""}`}
            onClick={() => document.getElementById("banner-file-input")?.click()}
          >
            {!previewURL ? (
              <ButtonX
                size="large"
                className={styles.primaryBtn}
              >
                Click to add
              </ButtonX>
            ) : (
              <>
                <img src={previewURL} alt="Uploaded Preview" className={styles.previewImage} />
                {(
                  <Box className={styles.removeBtn} onClick={(e) => {
                    e.stopPropagation()
                    handleRemoveImage()
                  }}>Remove</Box>
                )}
              </>
            )}
          </div>
          {/* Hidden File Input */}
          <input
            id="banner-file-input"
            type="file"
            accept="image/png, image/jpeg, image/avif"
            onChange={handleFileChange}
          />

          {/*  Error Message */}
          {error && (
            <div className={styles.errorMessage}>
              <InfoOutlinedIcon className={styles.infoIcon} /> {error}
            </div>
          )}

          {/* Supported formats */}
          <div className={styles.supportedFormats}>
            Supported Formats: jpeg, .png, or .avif <br />
            Maximum file size: 1 MB
          </div>
        </Box>
      </div >
    </ModalX >
  );
};

export default AddBannerModal;
