import { useState } from "react";
import styles from "./SearchFilter.module.scss";
import { Box } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { csx } from "helpers/utils";

type PropType = {
  onSearch: (value: string) => void;
  placeholder?: string;
  className?: string;
}

const SearchFilter: React.FC<PropType> = ({ onSearch, placeholder = "Search...", className}) => {
  const [searchTxt, setSearchTxt] = useState<string>('');

  const handleSearch = () => {
    onSearch(searchTxt.trim());
  }

  const onSearchTxt = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { nativeEvent: { key } } = e;
    if (key === 'Enter') {
      handleSearch();
    }
  }

  return (
    <Box className={csx(styles['search-filter'], className)}>
      <input
        type='text'
        value={searchTxt}
        onChange={(e) => setSearchTxt(e.target.value)}
        placeholder={placeholder}
        onKeyDown={onSearchTxt}
      />
      <SearchIcon fontSize="small" className={styles['search-icon']} onClick={() => {
        handleSearch();
      }} />
    </Box >
  );

}

export default SearchFilter;


