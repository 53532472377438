import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Api } from "../api/axiosInstance";

export type CollectionsData = {
  id: number | string;
  unique_key: number | string;
  name: string | null;
  marketplace_unique_id: number | string | null;
  image_url: string | null;
}

export type CollectionBannerFormData = {
  banner_name: string | null;
  banner_link: string | null;
  banner: string | File | null;
  banner_alt_text: string | null;
  collections: CollectionsData[];
};

export type CollectionBannerForm = {
  formData: CollectionBannerFormData;
  formErrors: Record<string, string>;
}

export const createCollectionBanner = createAsyncThunk("createCollectionBanner", async ({ headers, formData }: any, { rejectWithValue }) => {
  const formattedUrl = "/solomon/collection_banners";
  return await Api({ url: formattedUrl, method: "POST", headers, data: formData, rejectWithValue });
});

export const fetchCollectionBannerDetails = createAsyncThunk("fetchCollectionBannerDetails", async ({ id }: { id: string; headers: any }, { rejectWithValue }) => {
  const url = `/solomon/collection_banners/${id}`;
  return await Api({ url, method: "GET", rejectWithValue });
});

// Update collection banner
export const updateCollectionBanner = createAsyncThunk("updateCollectionBanner", async ({ id, formData, headers }: any, { rejectWithValue }) => {
  const url = `/solomon/collection_banners/${id}`;
  return await Api({ url, headers, method: "PUT", data: formData, rejectWithValue });
});

const searchCollectionFormatRequest = (data: any): string => {
  let url = `/solomon/collections/collection_search?`;
  url += `q[title_i_cont]=${data.searchTerm}&`;
  for (let i = 0; i < data.selectedIds.length; i += 1) {
    url += `q[id_not_in][]=${data.selectedIds[i]}&`;
  }
  return url
}

export const searchCollections = createAsyncThunk("searchCollections", async (data: { searchTerm: string; selectedIds: number[] }, { rejectWithValue }) => {
  const url = searchCollectionFormatRequest(data);
  return await Api({ url, method: "GET", rejectWithValue });
});

export const deleteCollectionBanner = createAsyncThunk("deleteCollectionBanner", async ({ id }: any, { rejectWithValue }) => {
  const url = `/solomon/collection_banners/${id}`;
  return await Api({ url, method: "DELETE", rejectWithValue });
});

const initialState: CollectionBannerForm = {
  formData: {
    banner_name: null,
    banner_link: null,
    banner: null,
    banner_alt_text: null,
    collections: []
  },
  formErrors: {}
};

const collectionBannerFormSlice = createSlice({
  name: "collectionBannerForm",
  initialState,
  reducers: {
    resetForm: (state) => {
      Object.assign(state, initialState);
    },
    updateForm: (state, action: PayloadAction<CollectionBannerFormData>) => {
      state.formData = action.payload;
    },
    updateErrors: (state, action: PayloadAction<Record<string, string>>) => {
      state.formErrors = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCollectionBannerDetails.pending, (state) => {

    });
    builder.addCase(fetchCollectionBannerDetails.fulfilled, (state, action: PayloadAction<CollectionBannerFormData>) => {
      state.formData = action.payload;
    });

    builder.addCase(fetchCollectionBannerDetails.rejected, (state, action) => {

    });
  },
});

// Export actions and reducer
export const { resetForm, updateForm, updateErrors } = collectionBannerFormSlice.actions;
export default collectionBannerFormSlice.reducer;
