import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box } from "@mui/material";
import { RootState } from "../../store";
import styles from "./CollectionBanner.module.scss";
import Loader from "components/atoms/Loader/Loader";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import { collectionBannerColumns } from "./CollectionBanner.constant";
import { CollectionBannerDataHash, collectionBannersApi } from "slices/collectionBannerSlice";
import { useNavigate } from "react-router-dom";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import AddBannerModal from "./AddBannerModal";
import SearchFilter from "components/molecules/SearchFilter/SearchFilter";
import ModalX from "components/atoms/ModalX/ModalX";
import { createCollectionBanner, deleteCollectionBanner } from "slices/collectionBannerFormSlice";
import { setCollectionBannerNameFilter } from "slices/filtersSlice";

const PAGE_TITLE = "Collection Banner";

const CollectionBanner = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loaderActive, setLoaderActive] = useState<boolean>(true);
  const [filterApplied, setFilterApplied] = useState<boolean>(true);
  const [showAddBannerModal, setShowAddBannerModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [bannerToDelete, setBannerToDelete] = useState<number | null>(null);
  const [pageChanged, setPageChanged] = React.useState<Boolean>(true);

  const collectionBannersData = useAppSelector(
    (state: RootState) => state.collectionBanner
  );

  const collectionBannerNameFilter = useAppSelector(
    (state: RootState) => state.filters.collectionBannerNameFilter
  );

  const tableData = useMemo(() => {
    return collectionBannersData.collectionBannerData?.data || [];
  }, [collectionBannersData.collectionBannerData]);

  const totalCount: number = collectionBannersData?.collectionBannerData?.total_count || 0;

  useEffect(() => {
    if (filterApplied || pageChanged) {
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied, page]);

  const fetchData = async () => {
    const payload = { collectionBannerNameFilter, page, rowsPerPage };
    try {
      await dispatch(collectionBannersApi({ ...payload })).unwrap();
    } catch (e) {
      console.error(e);
    } finally {
      setPageChanged(false);
      setFilterApplied(false);
      setLoaderActive(false);
    }
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1);
    setPageChanged(true);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
  };

  const handleSearch = (value: string) => {
    dispatch(setCollectionBannerNameFilter(value))
    setPage(1);
    setFilterApplied(true);
  };

  const handleAddBanner = async (banner_name: string, banner_link: string, banner: File | null) => {
    setShowAddBannerModal(false);
    try {
      const formData = {
        banner_name,
        banner_link,
        banner
      }
      const headers = { "content-type": 'multipart/form-data' }
      dispatch(createCollectionBanner({ headers, formData })).unwrap();
    } catch (e) {
      console.error(e);
    } finally {
      setFilterApplied(false);
      setLoaderActive(false);
    }
  }

  const handleDeleteBanner = (bannerId: number) => {
    setBannerToDelete(bannerId);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (bannerToDelete !== null) {
      try {
        setLoaderActive(true);
        await dispatch(deleteCollectionBanner({ id: bannerToDelete })).unwrap();
        setFilterApplied(true);
      } catch (error) {
        console.error("Failed to delete banner:", error);
      } finally {
        setBannerToDelete(null);
        setDeleteModalOpen(false);
        setLoaderActive(false);
      }
    }
  };

  const cancelDelete = () => {
    setBannerToDelete(null);
    setDeleteModalOpen(false);
  };

  const collectionsCountToDelete = useMemo(() => {
    return tableData.find((banner) => banner.id === bannerToDelete)?.collections_count;
  }, [bannerToDelete, tableData]);

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>
        <ButtonX
          className={styles.primaryBtn}
          onClick={() => setShowAddBannerModal(true)} // Open modal
        >
          Add Banner
        </ButtonX>
      </MainHeader>
      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchFilter onSearch={handleSearch} placeholder="Search By Name" />
        </Box>
      </Box>

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<CollectionBannerDataHash>
          data={tableData}
          totalCount={totalCount}
          columns={collectionBannerColumns({
            navigate,
            handleDelete: handleDeleteBanner
          })}
          sortedColumn={collectionBannersData.sortedColumn}
          handleSort={(sortedColumn) => console.log(sortedColumn)}
          sortDirection={collectionBannersData.sortDirection}
          showPagination
          currentPage={page - 1}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      )}
      <AddBannerModal
        open={showAddBannerModal}
        onClose={() => setShowAddBannerModal(false)}
        onCreate={handleAddBanner}
      />
      <ModalX
        title="Remove this banner"
        open={deleteModalOpen}
        onClose={cancelDelete}
        primaryBtnLabel="Delete"
        secondaryBtnLabel="Keep"
        onSubmit={confirmDelete}
        className={styles.deleteBanner}
      >
        <div>
          {collectionsCountToDelete !== null && (
            <p>This banner will be removed from {collectionsCountToDelete} collections.</p>
          )}
        </div>
      </ModalX>
    </Box>
  );
};

export default CollectionBanner;
