import { Box, Divider } from "@mui/material";
import styles from "./BannerDetails.module.scss";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BannerMetaobject } from "slices/collectionFormSlice";
import { useAppSelector } from "hooks/redux-hooks";
import { RootState } from "store";
import ClickTooltip from "components/atoms/ClickTooltip/ClickTooltip";
import CheckboxFilter from "components/atoms/CheckboxFilter/CheckboxFilter";
import Img from "components/atoms/Img/Img";
import ImgSelector from "components/molecules/ImgSelector/ImgSelector";

type BannerDetailsPropType = {
  label: JSX.Element | React.ReactNode;
  allowMobileBanner: boolean;
  allowWebBanner: boolean;
  allowMultipleMobileBanners?: boolean;
  allowMultipleWebBanners?: boolean;
  mobileBannerImages: (number|string)[];
  webBannerImages: (number|string)[];
  mobileBannerOptions: BannerMetaobject[];
  webBannerOptions: BannerMetaobject[];
  onChange: (type: 'web' | 'mobile', images: (string | number)[]) => void;
  onAddBannerOptions:  (type: 'web' | 'mobile', options: BannerMetaobject[]) => void;
  allowAddBanner?: boolean;
}

const getImageName = (img: File|string) => {
  if(img instanceof File){
    return img.name;
  }
  return img.split('/').slice(-1)[0];
}

const BannerDetails:React.FC<BannerDetailsPropType> = (props) => {
  const {
    label, allowMobileBanner, allowWebBanner, mobileBannerImages, webBannerImages,
    allowMultipleMobileBanners = true, allowMultipleWebBanners = true, onChange, onAddBannerOptions, mobileBannerOptions, webBannerOptions,
    allowAddBanner = false,
  } = props;

  const availableImages = useAppSelector((state: RootState) => state.collectionForm.mediaImageData) || [];
  const selectedMobileBannerImages = mobileBannerOptions.filter((banner) => mobileBannerImages.includes(banner.id));
  const selectedWebBannerImages = webBannerOptions.filter((banner) => webBannerImages.includes(banner.id));
  const [showBannerSelectionPopup, setShowBannerSelectionPopup] = useState<boolean>(false);

  const [uploadImgType, setUploadImgType] = useState<'web'|'mobile'>('web');

  const onDeleteBanner = (type: 'mobile' | 'web', id: number | string) => {
    let updatedList: (number|string)[] = [];
    if(type === 'mobile'){
      updatedList = mobileBannerImages.filter((bannerId) => bannerId !== id);
    }else{
      updatedList = webBannerImages.filter((bannerId) => bannerId !== id);
    }
    onChange(type, updatedList);
  }

  const handleAddBannerOptions = (ids: (string|number)[]) => {
    const newBanners = availableImages.filter((img) => ids.includes(img.id));
    onAddBannerOptions(uploadImgType, newBanners);
    let allBanners = uploadImgType === 'web' ? webBannerImages : mobileBannerImages;
    allBanners = [...allBanners, ...newBanners.map((banner) => banner.id)];
    setTimeout(() => {
      onChange(uploadImgType, allBanners);
      setShowBannerSelectionPopup(false);
    });
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.label}>
        {label}
      </Box>
      {(!!mobileBannerImages.length || !!webBannerImages.length) && allowMobileBanner && (
        <>
          <Box className={styles.imagesWrapper}>
            <p className={styles.title}>{'Mobile Banner'}</p>
            {selectedMobileBannerImages.map((img) => {
              return <BannerImage 
                image={img.image_url as File | string} 
                onDelete={() => {onDeleteBanner('mobile', img.id )}}
                title={img.display_name || ''}
              />
            })}
            <BannerSelectionPopup
              open={showBannerSelectionPopup && uploadImgType === 'mobile'}
              bannerOptions={mobileBannerOptions}
              type={'mobile'}
              handleAddBannerOptions={handleAddBannerOptions}
              selectedOptins={mobileBannerImages}
              showPopover={(show) => {
                uploadImgType === 'mobile' && setShowBannerSelectionPopup(show);
              }}
              onChange={onChange}
              allowAddBanner={allowAddBanner}
            >
              <ButtonX color={'#211913'} size="small" variant="outlined" disabled={!allowMobileBanner} onClick={(e) => {
                setUploadImgType('mobile');
                setShowBannerSelectionPopup(true);
              }}>Add Mobile Banner</ButtonX>
            </BannerSelectionPopup>
          </Box>
          <Divider />
        </>
      )}
      {(!!mobileBannerImages.length || !!webBannerImages.length) && allowWebBanner && (
        <>
          <Box className={styles.imagesWrapper}>
            <p className={styles.title}>{'Web Banner'}</p>
            {selectedWebBannerImages.map((img) => {
              return <BannerImage 
                image={img.image_url as File | string}
                onDelete={() => {onDeleteBanner('web',  img.id)}}
                title={img.display_name || ''}
              />
            })}
            <BannerSelectionPopup
             open={showBannerSelectionPopup && uploadImgType === 'web'}
             bannerOptions={webBannerOptions}
             type={'web'}
             handleAddBannerOptions={handleAddBannerOptions}
             selectedOptins={webBannerImages}
             showPopover={(show) => {
              uploadImgType === 'web' && setShowBannerSelectionPopup(show);
            }}
            allowAddBanner={allowAddBanner}
            onChange={onChange}
            >
              <ButtonX color={'#211913'} size="small" variant="outlined" disabled={!allowWebBanner} onClick={() => {
                setUploadImgType('web');
                setShowBannerSelectionPopup(true);
              }}>Add Web Banner</ButtonX>
            </BannerSelectionPopup>
          </Box>
          <Divider />
        </>
      )}
      {!mobileBannerImages.length && !webBannerImages.length && (
        <Box className={styles.actionBtns}>
          {allowMobileBanner && (
            <BannerSelectionPopup
              open={showBannerSelectionPopup && uploadImgType === 'mobile'}
              bannerOptions={mobileBannerOptions}
              type={'mobile'}
              handleAddBannerOptions={handleAddBannerOptions}
              selectedOptins={mobileBannerImages}
              showPopover={(show) => {
                uploadImgType === 'mobile' && setShowBannerSelectionPopup(show);
              }}
              allowAddBanner={allowAddBanner}
              onChange={onChange}
            >
              <ButtonX color={'#211913'} size="small" variant="outlined" disabled={!allowMobileBanner} onClick={() => {
                setUploadImgType('mobile');
                setShowBannerSelectionPopup(true);
              }}>Add Mobile Banner</ButtonX>
            </BannerSelectionPopup>
          )}
          {allowWebBanner && (
            <BannerSelectionPopup
              open={showBannerSelectionPopup && uploadImgType === 'web'}
              bannerOptions={webBannerOptions}
              type={'web'}
              handleAddBannerOptions={handleAddBannerOptions}
              selectedOptins={webBannerImages}
              showPopover={(show) => {
                uploadImgType === 'web' && setShowBannerSelectionPopup(show);
              }}
              allowAddBanner={allowAddBanner}
              onChange={onChange}
            >
              <ButtonX color={'#211913'} size="small" variant="outlined" disabled={!allowWebBanner} onClick={() => {
                setUploadImgType('web');
                setShowBannerSelectionPopup(true);
              }}>Add Web Banner</ButtonX>
            </BannerSelectionPopup>
          )}
        </Box>
      )}
    </Box>
  );
}

export default BannerDetails;

type BannerImagePropType = {
  image: File|string;
  title: string;
  onDelete: () => void;
}

const BannerImage:React.FC<BannerImagePropType> = (props) => {
  const {image, title, onDelete} = props;
  const imgSrc = (image instanceof File) ? URL.createObjectURL(image) : image;
  return (
    <Box className={styles.bannerImageBox}>
      <Box className={styles.bannerImg}>
        <img src={imgSrc} alt={title} />
      </Box>
      <p className={styles.filename}>{title}</p>
      <DeleteOutlinedIcon
        fontSize="small" 
        className={styles.deleteIcon}
        onClick={() => onDelete()}
      />
    </Box>
  );
}

type BannerSelectionPopupPropType = {
  open: boolean;
  bannerOptions: BannerMetaobject[];
  type: 'web' | 'mobile';
  handleAddBannerOptions: (ids: (string | number)[]) => void;
  selectedOptins:  (string | number)[];
  showPopover: (show: boolean) => void;
  children: JSX.Element;
  onChange: (type: 'web' | 'mobile', ids : (string|number)[]) => void;
  allowAddBanner: boolean;
}

const BannerSelectionPopup:React.FC<BannerSelectionPopupPropType> = (props) => {
  const {open, bannerOptions, type, handleAddBannerOptions, selectedOptins, showPopover, children, onChange, allowAddBanner} = props;
  const [currentSelection, setCurrentSelection] = useState<(string|number)[]>([]);
  const [showSelectImageOptions, setShowSelectImageOptions] = useState<boolean>(false);

  useEffect(() => {
    if(open){
      setCurrentSelection([...selectedOptins]);
    }
  }, [open]);

  const doneSelection = () => {
    onChange(type, currentSelection);
    showPopover(false);
  }

  return (
    <ClickTooltip
      title={
        <BannerSelectionContent 
          type={type} 
          bannerOptions={bannerOptions} 
          setCurrentSelection={setCurrentSelection}
          currentSelection={currentSelection}
          doneSelection={doneSelection}
          setShowSelectImageOptions={setShowSelectImageOptions}
          allowAddBanner={allowAddBanner}
        />
      }
      show={open && !showSelectImageOptions}
      className={styles.popupStyle}
      showPopper={(show) => {
        !showSelectImageOptions && showPopover(show)
      }}
      placement={'top'}
    >
      <Box>
        {children}
        {showSelectImageOptions && 
          <ImgSelector 
            multiple
            onSelect={handleAddBannerOptions}
            onClose={() => setShowSelectImageOptions(false)}
            open
          />
        }
      </Box>
    </ClickTooltip>
  );
}


type BannerSelectionContentPropType = {
  type: 'web' | 'mobile';
  bannerOptions: BannerMetaobject[];
  setCurrentSelection:  (ids: (string | number)[]) => void;
  currentSelection: (string|number)[];
  doneSelection: () => void;
  setShowSelectImageOptions: (value: boolean) => void;
  allowAddBanner: boolean;
}


const BannerSelectionContent:React.FC<BannerSelectionContentPropType> = (props) => {
  const {type, bannerOptions, setCurrentSelection, currentSelection, doneSelection, setShowSelectImageOptions, allowAddBanner} = props;
  const [searchTxt, setSearchTxt] = useState<string>('');

  const getImgUrl = useCallback((img: File | string) => {
    if  (typeof img === 'string') return img;
    return URL.createObjectURL(img)
  }, [bannerOptions]);

  const updateSelection = (id: string | number) => {
    if(currentSelection.includes(id)){
      setCurrentSelection(currentSelection.filter((item) => item !=  id));
    }else{
      setCurrentSelection([...currentSelection, id]);
    }
  }

  return (
    <Box className={styles.bannerOptionsWrapper}>
      {allowAddBanner && (
        <Box className={styles.heading}>
          <p className={styles.title}>{`Add ${type} banner`}</p>
          <p className={styles.addBanner} onClick={() => setShowSelectImageOptions(true)}>+ Upload Banner</p>
        </Box>
      )}
      <Box className={styles.searchBox}>
        <input
          type='text' 
          value={searchTxt} 
          onChange={(e) => setSearchTxt(e.target.value)} 
          placeholder='Type...'
        />
      </Box>
      <Box className={styles.options}>
        {bannerOptions.map((bannerOption) => {
          if(!(bannerOption.display_name || '').toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())) return null;
          return <Box className={styles.option} onClick={() => updateSelection(bannerOption.id)}>
            <CheckboxFilter
              key={bannerOption.id}
              label={
                <Box className={styles.label}>
                  <Img src={getImgUrl(bannerOption.image_url)} alt={bannerOption.display_name || ''} />
                  <p>{bannerOption.display_name || ''}</p>
                </Box>
              }
              checked={currentSelection.includes(bannerOption.id)}
              onChange={() => {}}
            />
          </Box>
        })}
      </Box>
      <Box className={styles.footerSection}>
        <ButtonX size="small" onClick={doneSelection}>Done</ButtonX>
      </Box>
    </Box>
  );
}