import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box, Fab, Tab, Tabs, useMediaQuery, useTheme, } from "@mui/material";
import { RootState } from "../../store";
import styles from "./VendorDataValidations.module.scss";
import { downloadVendorDataValidationRequestAttachedFilesApi, downloadVendorDataValidationRequestProcessedResponseApi, setSort, createVendorDataValidationRequest, downloadRefCsvFile} from "slices/vendorDataValidationsSlice";
import Loader from "components/atoms/Loader/Loader";
import {
  resetFilter,
  setVendorDataValidationRequestReportFileNameFilter,
  setVendorDataValidationRequestReportStatusFilter
} from "../../slices/filtersSlice";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import SearchByFilter, {
  AppliedFilterType,
  SearchSelections,
} from "../../components/molecules/SearchByFilter/SearchByFilter";

import SelectFilter from "../../components/molecules/SelectFilter/SelectFilter";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { vendorDataValidationRequestsApi, VendorDataValidationRequestDataHash } from "slices/vendorDataValidationsSlice";
import { vendorDataValidationsRequestsColumns } from "./VendorDataValidations.constant";
import LightTooltip from "components/atoms/LightTooltip/LightTooltip";
import UploadModal from "./UplodModal";
import { downloadFile } from "helpers/utils";
import TreeDropDownX from "components/molecules/TreeDropDownX/TreeDropDownX";
import FileUpload, { SampleFile } from "components/molecules/FileUpload/FileUpload";
import SelectX from "components/atoms/SelectX/SelectX";
import { fetchCategories } from "slices/productFormSlice";
import { NotificationType, showNotification } from "slices/notificationSlice";

const PAGE_TITLE = "Vendor Data Validations";

const statusOptions = [
  { value: 'processing', label: 'Processing'},
  { value: 'success', label: 'Success'},
  { value: 'failed', label: 'Failed'},
  { value: 'processed', label: 'Processed'},
];

const searchByOptions = [
  {id: 'fileName', label: 'File Name'}
]

export const vendorDataValidationsRequestTypes = {
  vendor_data_excel_upload: 'vendor_data_excel_upload'
}

const VendorDataValidations = () => {
  const dispatch = useAppDispatch();

  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);
  const [uploadRequestType , setUploadRequestType] = useState<string>('');
  const [fileUploading, setFileUploading] = useState<boolean>(false);

  const vendorDataValidationsRequestsData = useAppSelector(
    (state: RootState) => state.vendorDataValidationsRequests
  );
  const vendorDataValidationRequestReportStatusFilter = useAppSelector(
    (state: RootState) => state.filters.vendorDataValidationRequestReportStatusFilter
  );
  const vendorDataValidationRequestReportFileNameFilter = useAppSelector(
    (state: RootState) => state.filters.vendorDataValidationRequestReportFileNameFilter
  );
  const categories = useAppSelector((state) => state.productForm.categories).map((c) => ({label: c.name, value: c.id})) as {label: string, value: string}[];
  const [category, setCategory] = useState<string | number>('');

  const tableData = vendorDataValidationsRequestsData.vendorDataValidationRequestData?.data || [];
  const totalCount: number = vendorDataValidationsRequestsData?.vendorDataValidationRequestData?.total_count || 0;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const appliedSearchFilters: AppliedFilterType[] = [
    ...((!!vendorDataValidationRequestReportFileNameFilter
      ? [
        {
          id: "fileName",
          label: "File Name",
          value: vendorDataValidationRequestReportFileNameFilter,
          type: "search",
        },
      ]
      : []) as AppliedFilterType[]),
    ...((!!vendorDataValidationRequestReportStatusFilter
      ? [
        {
          id: "status",
          label: "Status",
          value: vendorDataValidationRequestReportStatusFilter,
          type: "select",
          options: statusOptions,
        },
      ]
      : []) as AppliedFilterType[])
  ] as AppliedFilterType[];

  useEffect(() => {
    if (filterApplied) {
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const handleSort = (column: keyof VendorDataValidationRequestDataHash) => {
    const direction = column === vendorDataValidationsRequestsData.sortedColumn && vendorDataValidationsRequestsData.sortDirection === "asc" ? "desc" : "asc";
    dispatch(setSort({ column, direction }));
  };

  const handleRequest = async (handler: (headers: { Authorization: string } | undefined) => Promise<any>) => {
    const userInfo = localStorage.getItem('userInfo');
    if(userInfo){
      let data: any = undefined;
      try{
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
        data = await handler(headers);
      }catch(e) {
        console.error(e);
      }finally{
        setFilterApplied(false);
        setLoaderActive(false);
        return data;
      }
    }else{
      dispatch(resetAuth());
      dispatch(resetFilter());
    }
  }

  const fetchData = () => {
    const payload = { vendorDataValidationRequestReportStatusFilter, vendorDataValidationRequestReportFileNameFilter, page, rowsPerPage};
    let handler = (headers: { Authorization: string } | undefined) => dispatch(vendorDataValidationRequestsApi({ headers, ...payload })).unwrap();
    handleRequest(handler);
    let fetch_categories_handler = (headers: { Authorization: string } | undefined) => dispatch(fetchCategories({ headers })).unwrap();
    handleRequest(fetch_categories_handler);
  }

  const downloadAttachments = async (id: string | number) => {
    const payload = { id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(downloadVendorDataValidationRequestAttachedFilesApi({ headers, ...payload })).unwrap();
    const data = await handleRequest(handler);
    if(data) {
      const urls = data?.urls || [];
      urls.forEach((url:string) => {
        const decodedUrl = decodeURIComponent(url);
        const match = decodedUrl.match(/(?<=filename=").*?(?=")/);
        const link = document.createElement('a');
        const fileName = match ? match[0] : 'Attachment.zip';
        link.href = url;
        link.target = "_blank";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }

  const downloadResponse = async (id: string | number) => {
    const payload = { id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(downloadVendorDataValidationRequestProcessedResponseApi({ headers, ...payload })).unwrap();
    const downloadApi = async () => {
      const data = await handleRequest(handler);
      return {payload: data};
    };
    await downloadFile({downloadApi, fileName: 'processed_response.csv', fileType: 'text/csv'});
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const handleFilterValueChange = ({
    id,
    value,
  }: {
    id: string;
    value: string | boolean | string[];
  }) => {
    switch (id) {
      case "status": {
        dispatch(setVendorDataValidationRequestReportStatusFilter(value as string));
        break;
      }
      case "fileName": {
        dispatch(setVendorDataValidationRequestReportFileNameFilter(value as string));
        break;
      }
    }
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const onSearchByClear = (id: string) => {
    handleFilterValueChange({ id, value: "" });
  };

  const onSearchByAllClear = () => {
    dispatch(setVendorDataValidationRequestReportFileNameFilter(""));
    dispatch(setVendorDataValidationRequestReportStatusFilter(""));
    setPage(0);
    setTimeout(() => {setFilterApplied(true)}, 0);
  };

  const downloadSampleFile = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      try{
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        const downloadApi = () => dispatch(downloadRefCsvFile({headers, category_id: category}));
        await downloadFile({downloadApi, fileName: 'Sample file', fileType: 'text/csv'});
      }catch(e){
        console.error(e);
      }
    }
  }

  const create_vendor_data_validation_request = async (file: File) => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      try{
        dispatch(showNotification({message: 'Uploading file...', type: NotificationType.Info}));
        const token = JSON.parse(userInfo).token;
        const headers = token ? { Authorization: `${token}` } : undefined;
        const payload  = new FormData();
        payload.append('csv_file', file);
        payload.append('parent_id', category.toString());
        await dispatch(createVendorDataValidationRequest({headers, payload}));
        setFilterApplied(true);
      }catch(e){
        console.error(e);
      }
    }else{
      console.error('User info not found');
    }
  }
  
  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>

        <ButtonX color="#000" variant="outlined" onClick={() => {
          setShowUploadFileModal(true);
         }}>
          Upload file
        </ButtonX>

      </MainHeader>
      <Box className={styles.filterAndDownloadWrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchByFilter
            filters={searchByOptions}
            onSearch={handleFilterValueChange}
          />
          <SelectFilter
            label={"Status"}
            value={vendorDataValidationRequestReportStatusFilter}
            options={statusOptions}
            onChange={(val) => {
              dispatch(setVendorDataValidationRequestReportStatusFilter(val));
              setFilterApplied(true);
            }}
          />
        </Box>
      </Box>
      {
        appliedSearchFilters.length ? (
          <SearchSelections
            appliedFilters={appliedSearchFilters}
            allClear={onSearchByAllClear}
            onClear={onSearchByClear}
          />
        ) : null
      }

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<VendorDataValidationRequestDataHash>
          data={tableData}
          totalCount={totalCount}
          columns={vendorDataValidationsRequestsColumns({downloadAttachments, downloadResponse})}
          sortedColumn={vendorDataValidationsRequestsData.sortedColumn}
          handleSort={handleSort}
          sortDirection={vendorDataValidationsRequestsData.sortDirection}
          showPagination
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      )}

      <FileUpload
        open={showUploadFileModal}
        label={"Bulk Product Upload"}
        heading={
          <Box className={styles.sampleFileHeading}>
            <SelectX 
              wrapperClass={styles.customSelect}
              options={categories} 
              onChange={(value) => setCategory(value)} 
              value={category as string} 
              placeholder="Select Category"
              allowSearch
              />
            {!!category && (
              <SampleFile 
                sampleFileHandler={downloadSampleFile}
              />
            )}
          </Box>
        }
        fileType={['text/csv']}
        multiple={false}
        onDiscard={() => setShowUploadFileModal(false)}
        onSubmit={(files) => {
          create_vendor_data_validation_request(files[0]);
        }}
      />
    </Box>
  );
};

export default VendorDataValidations;

