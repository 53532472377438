import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import { sortRecords } from "../helpers/utils";
import { AttachmentFile } from "../constants";

export type VendorDataValidationRequestDataHash = {
  id: string | number;
  unique_key: string | number;
  filenames: string[];
  request_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  updated_by: string;
  total_count: number | null;
  success_count: number | null;
  error_count: number | null;
  processed_response_present: boolean;
}

type VendorDataValidationRequestData = {
  data: VendorDataValidationRequestDataHash[];
  total_count: number;
};

type VendorDataValidationRequestApiState = {
  vendorDataValidationRequestData?: VendorDataValidationRequestData | null;
  vendorDataValidationRequestStatus: "idle" | "loading" | "failed";
  vendorDataValidationRequestError: string | null;
  sortDirection: 'asc' | 'desc';
  sortedColumn: keyof VendorDataValidationRequestDataHash | null;
};

const initialState: VendorDataValidationRequestApiState = {
  vendorDataValidationRequestData: null,
  vendorDataValidationRequestStatus: "loading",
  vendorDataValidationRequestError: null,
  sortDirection: 'asc',
  sortedColumn: null
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, download:boolean = false): string => {
  let url = `/solomon/vendor_data_validations?`; 
    url += `q[primary_attachment_attachment_blob_filename_cont]=${requestData.vendorDataValidationRequestReportFileNameFilter}&`
    url += `q[secondary_attachment_attachment_blob_filename_cont]=${requestData.vendorDataValidationRequestReportFileNameFilter}&`
    url += `q[status_eq]=${requestData.vendorDataValidationRequestReportStatusFilter}&`
    url += `q[m]=or&`
    url += `page=${requestData.page + 1 }&`;
    url += `per_page=${requestData.rowsPerPage}`;
  return url;
}

export const vendorDataValidationRequestsApi = createAsyncThunk(
  "vendorDataValidationRequestsApi",
  async ({headers, ...requestData} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const formattedRequestUrl = formatRequest(requestData);
      const response = await axiosInstance.get(formattedRequestUrl, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const createVendorDataValidationRequest = createAsyncThunk(
  "createVendorDataValidationRequest",
  async ({headers, payload} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const formattedRequestUrl = '/solomon/vendor_data_validations';
      const response = await axiosInstance.post(formattedRequestUrl, payload, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
)

export const downloadVendorDataValidationRequestProcessedResponseApi = createAsyncThunk(
  "downloadVendorDataValidationRequestProcessedResponseApi",
  async ({id, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get(`/solomon/vendor_data_validations/${id}/download_processed_response`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const downloadVendorDataValidationRequestAttachedFilesApi = createAsyncThunk(
  "downloadVendorDataValidationRequestAttachedFilesApi",
  async ({id, headers} : any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    try {
      const response = await axiosInstance.get(`/solomon/vendor_data_validations/${id}/attachment_urls`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({errors: "Unauthorized! Login again"}); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const downloadRefCsvFile = createAsyncThunk(
  "downloadRefCsvFile",
  async ({ headers, category_id }: any, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {
      headers: headers || {},
    };
    
    try {
      const response = await axiosInstance.get(`/solomon/vendor_data_validations/download_ref_csv_file?category_id=${category_id}`, config);
      const resData = response.data;

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        return rejectWithValue({ errors: "Unauthorized! Login again" }); // Capture 401 in rejected state
      }
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

const vendorDataValidationRequestTrackingSlice = createSlice({
  name: 'vendorDataValidationRequestTrackingSlice',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof VendorDataValidationRequestDataHash | null; direction: 'asc' | 'desc' }>) => {
      const { column, direction } = action.payload;
      // Sorting logic
      if(state.vendorDataValidationRequestData?.data && column){
        const sorted = sortRecords(state.vendorDataValidationRequestData.data, column, direction);
        state.vendorDataValidationRequestData.data = sorted as VendorDataValidationRequestDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(vendorDataValidationRequestsApi.pending, (state) => {
        state.vendorDataValidationRequestStatus = "loading";
        state.vendorDataValidationRequestError = null;
      })
      .addCase(
        vendorDataValidationRequestsApi.fulfilled,
        (state, action: PayloadAction<VendorDataValidationRequestData>) => {
          state.vendorDataValidationRequestStatus = "idle";
          state.vendorDataValidationRequestData = action.payload;
        }
      )
      .addCase(vendorDataValidationRequestsApi.rejected, (state, action) => {
        state.vendorDataValidationRequestStatus = "failed";
        if (action.payload) {
          state.vendorDataValidationRequestError =
            (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.vendorDataValidationRequestError = action.error.message || "Error occured";
        }
      })
  }
});

export const { setSort } = vendorDataValidationRequestTrackingSlice.actions;
export default vendorDataValidationRequestTrackingSlice.reducer;
