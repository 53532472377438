import { Box, useMediaQuery, useTheme } from "@mui/material";
import styles from "./MainHeader.module.scss";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { csx } from "helpers/utils";

type PropType = {
  label: string;
  children?: JSX.Element | React.ReactNode;
  allowBack?: boolean;
  className?: string;
}

const MainHeader: React.FC<PropType> = ({label, children: rightSection = null, allowBack = false, className}) => {

  const handleBack = () => {
    window.history.back();
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={csx(styles.headerWrapper, className)}>
      <p className={styles.label}>
        {allowBack && isMobile && <ArrowBackIosNewIcon className={styles.angleBack} onClick={handleBack} />}
        {allowBack && !isMobile && <ArrowBackIcon className={styles.arrowBack} fontSize='small' onClick={handleBack} />}
        {label}
      </p>
      <Box className={styles.rightSection}>
        {rightSection}
      </Box>
    </Box>
  );
}

export default MainHeader;